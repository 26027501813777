import * as React from "react";
import { useTranslation } from "react-i18next";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { connect } from "react-redux";
import {
  CompanyInfoSteps,
  EsgConfigurationDTO,
} from "../../../model/AdminDTO";
import { setCompanyInfoStep } from "../../../redux/admin/admin-actions";
import ScreenWrapper from "../../AdminComponent/ScreenWrapper/ScreenWrapper";
import { getRatingESG } from "../../../redux/admin/admin-actions";
import CardContainerWithButtons from "../../Common/CardContainerWithButtons/CardContainerWithButtons";
import { AppState } from "../../../redux/store";
import { NewEsgCertificateDTO } from "../../../model/CompanyDTO";
import "./AddEsgCertificate.scss";
import { addEsgCertificate } from "../../../redux/company";
import moment from "moment";
import { getEsgCertificate } from "../../../redux/company/company-actions";
import EsgCertificate from "./EsgCertificate";

const AddEsgCertificate: React.FC<any> = ({
  onPrevious,
  getRatingESG,
  addEsgCertificate,
  vendorVatNumber,
  getEsgCertificate,
}) => {
  const { t } = useTranslation();

  const [esgCertificate, setEsgCertificate] = React.useState(
    {} as NewEsgCertificateDTO
  );

  const [selectedConfiguration, setSelectedConfiguration] =
      React.useState<EsgConfigurationDTO | null>(null);

  React.useEffect(() => {
    getRatingESG();
  }, [getRatingESG]);



  const confirm = async () => {
    esgCertificate.configurationId = selectedConfiguration?.id ?? -1;
    esgCertificate.vendorVatNumber = vendorVatNumber;
    await addEsgCertificate(esgCertificate);
    await getEsgCertificate();
    onPrevious();
  };

  const areDatesValid =
    !esgCertificate.emissionDate ||
    !esgCertificate.expiryDate ||
    moment(esgCertificate.expiryDate).isAfter(esgCertificate.emissionDate);

  const disabledButton =
    !selectedConfiguration ||
    !(esgCertificate.classRating || esgCertificate.scoreRating) ||
    !esgCertificate.base64 ||
    !areDatesValid;


  return (
    <>
      <ScreenWrapper
        title={t("supplier.addEsgCertificate.title")}
        description={t("supplier.addEsgCertificate.description")}
      >
        <CardContainerWithButtons
          title={t("supplier.addEsgCertificate.cardTitle")}
          onPreviousButtonClick={onPrevious}
          onConfirmButtonClick={confirm}
          confirmButtonDisabled={disabledButton}
          error={
            areDatesValid ? "" : t("supplier.addEsgCertificate.errorDates")
          }
        >
          <EsgCertificate 
            setEsgCertificate={setEsgCertificate} esgCertificate={esgCertificate} 
            setSelectedConfiguration={setSelectedConfiguration} selectedConfiguration={selectedConfiguration} >

          </EsgCertificate>
        </CardContainerWithButtons>
      </ScreenWrapper>
    </>
  );
};

const mapStateToProps = (store: AppState) => {
  return {
    vendorVatNumber: store.auth?.onBoardingData?.vatNumber,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    getRatingESG: () => dispatch(getRatingESG()),
    setCompanyInfoStep: (payload: CompanyInfoSteps) =>
      dispatch(setCompanyInfoStep(payload)),
    addEsgCertificate: (esgCertificate: NewEsgCertificateDTO) =>
      dispatch(addEsgCertificate(esgCertificate)),
    getEsgCertificate: () => dispatch(getEsgCertificate()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEsgCertificate);
