import * as React from "react";
import { connect } from "react-redux";
import ContainerRow from "../../Common/ContainerRow/ContainerRow";
import DatePicker, {
  DatePickerTypes,
} from "../../Common/DatePicker/DatePicker";
import MyDropdown from "../../Common/MyDropdown/MyDropdown";
import TextInput from "../../Common/TextInput/TextInput";
import { useTranslation } from "react-i18next";
import { AppState } from "../../../redux/store";
import CustomUploadFile from "../../Common/CustomUploadFile/CustomUploadFile";
import { EsgConfigurationDTO, MimeTypes, RatingType } from "../../../model/AdminDTO";

const EsgCertificate: React.FC<any> = ({
  isFetchingRatingESG,
  esgConfiguration,
  esgCertificate,
  setEsgCertificate,
  selectedConfiguration,
  setSelectedConfiguration,
}) => {
  const { t } = useTranslation();

  const [isClassRating, setIsClassRating] = React.useState(false);
  const [isScoreRating, setisScoreRating] = React.useState(false);

  const [openCalendars, setOpenCalendars] = React.useState<{
    [key: string]: boolean;
  }>({});

  const handleConfigurationChange = (value: EsgConfigurationDTO) => {
    setSelectedConfiguration(value);
    updateCertificate({
      configurationId: value.id,
      classRating: null,
      scoreRating: null,
    });
    if (value.ratingType === RatingType.CLASS) {
      setIsClassRating(true);
      setisScoreRating(false);
    } else if (value.ratingType === RatingType.SCORE) {
      setisScoreRating(true);
      setIsClassRating(false);
    }
  };
  const updateCertificate = (inputData: any) => {
    const updatedData = { ...esgCertificate, ...inputData };
    setEsgCertificate(updatedData);
  };

  const handleCalendarOpen = (calendarId: string, isOpen: boolean) => {
    setOpenCalendars((prev) => ({ ...prev, [calendarId]: isOpen }));
  };

  const acceptedCertificateTypes = [MimeTypes.PDF];

  return (
    <div>
      <ContainerRow>
        <div className="inputContainer">
          {!isFetchingRatingESG && esgConfiguration && (
            <MyDropdown
              links={esgConfiguration}
              labelExtractor={(item: any) => item.certificationType}
              inputValue={selectedConfiguration}
              title={t("supplier.addEsgCertificate.certificateType")}
              onChange={handleConfigurationChange}
            />
          )}
        </div>
        <div className="inputContainer">
          {(isClassRating && (
            <TextInput
              placeholder={t(
                "supplier.addEsgCertificate.classRatingPlaceholder"
              )}
              title={t("supplier.addEsgCertificate.classRating")}
              value={esgCertificate.classRating}
              onChange={(classRating) => updateCertificate({ classRating })}
            />
          )) ||
            (isScoreRating && (
              <TextInput
                placeholder={t(
                  "supplier.addEsgCertificate.scoreRatingPlaceholder"
                )}
                title={t("supplier.addEsgCertificate.scoreRating")}
                value={esgCertificate.scoreRating}
                onChange={(scoreRating) => updateCertificate({ scoreRating })}
              />
            ))}
        </div>
      </ContainerRow>
      <ContainerRow className="addEsgCertificate__datePickerContainer">
        <div className="inputContainer">
          <DatePicker
            title={t("supplier.addEsgCertificate.emissionDate")}
            dateInput={esgCertificate.emissionDate}
            onDateChange={(emissionDate) => updateCertificate({ emissionDate })}
            setOpenCalendar={({ focused }) =>
              handleCalendarOpen(`emissionDate`, focused as boolean)
            }
            openCalendar={openCalendars[`emissionDate`] || false}
          />
        </div>
        <div className="inputContainer">
          <DatePicker
            title={t("supplier.addEsgCertificate.expiryDate")}
            dateInput={esgCertificate.expiryDate}
            onDateChange={(expiryDate) => updateCertificate({ expiryDate })}
            type={DatePickerTypes.TODAY_AND_FUTURE}
            setOpenCalendar={({ focused }) =>
              handleCalendarOpen(`expiryDate`, focused as boolean)
            }
            openCalendar={openCalendars[`expiryDate`] || false}
          />
        </div>
      </ContainerRow>
      <ContainerRow>
        <div className="inputContainer">
          <CustomUploadFile
            title={t("supplier.addEsgCertificate.uploadCertificate")}
            fileData={esgCertificate.base64}
            acceptedTypes={acceptedCertificateTypes}
            onClickUpload={(base64, certificateName) =>
              updateCertificate({ base64, certificateName })
            }
            hasDownload={false}
          />
        </div>
      </ContainerRow>
    </div>
  );
};

const mapStateToProps = (store: AppState) => {
  return {
    esgConfiguration: store.admin.esgConfiguration,
    isFetchingRatingESG: store.admin.isFetchingRatingESG,
  };
};



export default connect(mapStateToProps)(EsgCertificate);
